import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { DebitService } from "./DebitService";

export const DebitCustomers = createAsyncThunk(
  "ls/GetSuperdealerResellerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await DebitService.DebitModule().DebitCustomers(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ViewDebitedCustomers = createAsyncThunk(
  "ls/ViewDebitedCustomers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await DebitService.DebitModule().ViewDebitedCustomers(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const UsersSlice = createSlice({
  name: "Users",
  initialState: "",
  extraReducers: {
    [ViewDebitedCustomers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ViewDebitedCustomers.fulfilled]: (state, action) => {
      return {
        ...state,
        ViewDebitCustomersSuccess: action.payload,
        loading: false,
      };
    },
    [ViewDebitedCustomers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [DebitCustomers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DebitCustomers.fulfilled]: (state, action) => {
      return {
        ...state,
        DebitCustomersSuccess: action.payload,
        loading: false,
      };
    },
    [DebitCustomers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default UsersSlice.reducer;
