import api, { formApi } from "../api";

const ProductsModule = () => {
  return {
    SingleAirtime: function (data) {
      return api.post("airtime/Airtime/sendairtime?Channel=1", data);
    },

    BulkAirtime: function (data) {
      const searchParams = new URLSearchParams(window.location.search);
      const q = searchParams.get("q");
      return formApi.post(
        `airtime/Airtime/sendairtime/bulk?bulkAirtime=${q}`,
        data
      );
    },

    SingleData: function (data) {
      return api.post("data/Service/senddata", data);
    },

    GetDataPlans: function (data) {
      return api.get(
        `data/Service/getdataplan?MobileNetwork=${data || 1}`,
        data
      );
    },

    GetProviders: function (data) {
      return api.get(`bills/BillerService/providers?serviceCode=${data}`, data);
    },

    GetProducts: function (data) {
      const { service_code, product_code } = data;
      return api.get(
        `bills/BillerService/products?serviceCode=${service_code}&providerCode=${product_code}`,
        data
      );
    },

    VerifyMeter: function (data) {
      return api.post("bills/BillerService/verifyelectricityymeter", data);
    },

    SingleElectricity: function (data) {
      return api.post("bills/BillerService/payelectricity", data);
    },

    VerifyCable: function (data) {
      return api.post("bills/BillerService/verifycabletv", data);
    },

    SingleCable: function (data) {
      return api.post("bills/BillerService/paycabletv", data);
    },

    ListTransaction: function (data) {
      // //console.log("data2", data);
      // return api.post("wallet/Wallet/wallettransactions", data);
      return api.post("wallet/Wallet/walletwithdownlinetransactions", data);
    },

    TransactionDetails: function (data) {
      // //console.log("data2", data);
      return api.post(`/Admin/reports/airtime/${data?.ref}`, data);
    },

    MainWallet: function (data) {
      return api.post("wallet/Wallet/walletmovements", data);
    },

    CommissionWalletReport: function (data) {
      //console.log("data new stuff", data);
      return api.get(
        `/wallet/Wallet/commissionmovements?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&keyword=${data.keyword}&ServiceType=${data.ServiceType}&StartDate=${data.startDate}&EndDate=${data.endDate}`
      );
      // return api.get(`/Wallet/commissionmovements?keyword=${data.keyword}&ServiceType=${data.ServiceType}&PageSize=${data.PageSize}&CurrentPage=${data.CurrentPage}`);
    },

    CusstomerListing: function (data) {
      // //console.log("data2", data);
      return api.get(
        `identity/Agent/customerlisting?agentrole=${data?.agentrole}`
      );
    },

    TransactionSummary: function (data) {
      // //console.log("data2", data);
      return api.get(
        `identity/Agent/summary`
        // ?AgentRoleId=${data?.AgentRoleId}
      );
    },
  };
};

export const ProductsService = {
  productsModule: ProductsModule,
};
