import axios from "axios";
import api from "../api";

const core_api = axios.create({
  baseURL: "https://coredev.ucard.store/api/",
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxODgiLCJqdGkiOiJjYjM4ODNlMmQxYjZiNGI4NTU4Zjk4MGI2NDczZTg0YjcyZGQ3NTg1NDNlY2M2OGE1ZTAzOWMyNmRlZjJiNzNjNTg0NTc3YjcxZmUzMzU0OCIsImlhdCI6MTY2Njk4NzUyOS41MjYyMjQsIm5iZiI6MTY2Njk4NzUyOS41MjYyMjgsImV4cCI6MTY5ODUyMzUyOS41MTczMDksInN1YiI6IjE0Iiwic2NvcGVzIjpbXX0.VNmhjo5gTYRaQTMAvIk_XVIb8SJHBoFzapNkCKibNTg3kWSbvLARGPS0dlRnMex0eb-Zb26l85CUupPcRI322xHC21X9W7NzG25UzxXHWk7KOelpUIRnjkS_aXXsuGWTZ_XnkGEkI_5NjQBtAoVx8cTgg3eD7_sYYD5kNiCpQslZe8-F6ABnz4y6RJR-p0pyNvbrhaEPoiPTvlyNf097v0UEi3TgQKAT6P4zAuFBNQATQqm6Uhtw0hAlDf5JJgMWqjNzx7jKQA7g3cuHAt-065-c8DY15xeW22qqiTKg7cc6R4BZz7V5XMw-Vs6bF27I1H8vB4CkF544ukm--IvFB_YyfRoYZ_t84JvVkYyLf8PlJDQELV6TYVQSHjbyu38CFqe-cfsOWMVwz9BbaFqjKZjNKthtqw1hC8Zkw3B0gxlxPyjDzAaa5G6ABS2_m2bDIbTnhCbysGKsPg9gjQVgWYSsueMko66n-RGnEVups09cbDGP7qKEoXl6PoQvcBwZOSDNQAQ9XYXhVzaEPx70ogqVONDn57B6lEkD0Nr4p0CzaVPJKn5nYYXdCWZQdcOBresK8TQ8-UASKCm3XgufaNsZ6bfeaLb-e5kwh-DaiLEDg0ucRApcJn_PL3ftwfNhnSUI742m_S016bB628aUHqUt6ll0dvZtnd2yBjza6WQ",
  },
});

const PerformanceModule = () => {
  const userData = JSON.parse(localStorage.getItem("user_profile"));

  return {
    GetCoreReports: function (data) {
      return api.post(`/wallet/Report`, data);
    },

    GetPerformanceGraph: function (data) {
      const { date, product } = data;
      return core_api.get(
        `/transactions/report/superdealer/performance/overview?customer_id=${userData?.walletId}&service_codes=${product}&date=${date}`,
        data
      );
    },

    ListTransaction: function (data) {
      const { page, per_page, keyword, startDate, endDate } = data;
      // page=1&per_page=10&customer_id=501&keyword=2783
      return core_api.get(
        `/transactions/report/listings-of-agent-and-downline-v2?customer_id=${userData?.walletId}&page=${page}&per_page=${per_page}&keyword=${keyword}&StartDate=${startDate}&EndDate=${endDate}`,
        data
      );
    },

    CusstomerListing: function (data) {
      const { page, per_page, keyword } = data;
      // page=1&per_page=10&customer_id=501&keyword=2783
      return core_api.get(
        `/report/agent/agent-downline-listings-v2?customer_id=${userData?.walletId}&page=${page}&per_page=${per_page}&keyword=${keyword}`,
        data
      );
    },

    AgentPerformanceList: function (data) {
      const { date, product, page, per_page } = data;
      return core_api.get(
        `/transactions/report/superdealer/performance/top-agents?customer_id=${userData?.walletId}&service_codes=${product}&date=${date}&page=${page}&per_page=${per_page}`,
        data
      );
    },

    AgentProductPerformanceList: function (data) {
      const { date, product, customer_id } = data;
      return core_api.get(
        `/transactions/report/superdealer/performance/agent-products?customer_id=${customer_id}&service_codes=${product}&date=${date}`,
        data
      );
    },

    AgentPerformanceListFromReseller: function (data) {
      const { date, product, page, per_page, customer_id } = data;
      return core_api.get(
        `/transactions/report/superdealer/performance/top-agents?customer_id=${customer_id}&service_codes=${product}&date=${date}&page=${page}&per_page=${per_page}`,
        data
      );
    },

    ResellerPerformanceList: function (data) {
      const { date, product, page, per_page } = data;
      return core_api.get(
        `/transactions/report/superdealer/performance/top-resselers?customer_id=${userData?.walletId}&service_codes=${product}&date=${date}&page=${page}&per_page=${per_page}`,
        data
      );
    },
  };
};

export const PerformanceService = {
  performanceModule: PerformanceModule,
};
