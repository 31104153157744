import api, { formApi } from "../api";

const SettingModule = () => {
  return {
    Onboarding: function (data) {
      return api.post("identity/Agent/onboard", data);
    },

    ChangePin: function (data) {
      return api.post("identity/Agent/changepin", data);
    },

    CreatePin: function (data) {
      return api.post("identity/Agent/setnewpin", data);
    },

    SetPin: function (data) {
      return api.post("identity/Agent/completepinreset", data);
    },

    ChangePassword: function (data) {
      return api.post("identity/Agent/changepassword", data);
    },

    ChangeTransactionPin: function (data) {
      return api.post("identity/Agent/initiatepinreset", data);
    },

    GetOTPByEmail: function (data) {
      return api.post("/", data);
    },

    ConfirmPassword: function (data) {
      return api.post("/", data);
    },

    GetBanks: function (data) {
      return api.get("wallet/Wallet/banks", data);
    },

    SendOTP: function (data) {
      return api.post("identity/Agent/sendOTP", data);
    },

    ResendOTP: function (data) {
      return api.post("identity/Agent/resendOTP", data);
    },

    VerifyOTP: function (data) {
      return api.post("identity/Agent/validateagenteotp", data);
    },

    ValidAuthOTP: function (data) {
      return api.post("identity/Agent/validateotp", data);
    },

    VerifyPassword: function (data) {
      return api.post("identity/Agent/verifypassword", data);
    },

    ChangeBankAccount: function (data) {
      return api.post("identity/Agent/update/bankdetails", data);
    },

    AddBankAccount: function (data) {
      return api.post("identity/Agent/bankdetails", data);
    },

    VerifyBankAccount: function (data) {
      return api.post("wallet/Wallet/validatebankaccount", data);
    },

    AddContactPerson: function (data) {
      return api.post("identity/Agent/addcontactdetails", data);
    },

    UploadAgentImage: function (data) {
      return formApi.post("identity/Agent/upload_document?type=1", data);
    },

    CreateWallet: function (data) {
      return api.post("identity/Agent/wallet", data);
    },
  };
};

export const SettingService = {
  settingsModule: SettingModule,
};
