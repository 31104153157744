import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ReportsService } from "./ReportsService";

export const ListTransaction = createAsyncThunk(
  "ls/ListTransaction",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().ListTransaction(
        payload
      );
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const AgentListTransaction = createAsyncThunk(
  "ls/ListTransaction",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().AgentListTransaction(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const MainWalletReport = createAsyncThunk(
  "ls/MainWalletReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().MainWallet(payload);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const ECOBankTransactions = createAsyncThunk(
  "ls/ECOBankTransactions",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response =
        await ReportsService.reportsModule().getECOBANKTransactions(value);

      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const CommissionWalletReport = createAsyncThunk(
  "ls/MainWalletReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().CommissionWalletReport(payload);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const CusstomerListing = createAsyncThunk(
  "ls/CusstomerListing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().CusstomerListing(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SingleCustomerListing = createAsyncThunk(
  "ls/SingleCustomerListing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().SingleCustomerListing(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const TransactionSummary = createAsyncThunk(
  "ls/TransactionSummary",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().TransactionSummary(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AirtimeReports = createAsyncThunk(
  "ls/AirtimeReports",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().AirtimeReports(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AirtimeReportsWithoutDownlines = createAsyncThunk(
  "ls/AirtimeReportsWithoutDownlines",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().AirtimeReportsWithoutDownlines(
          payload
        );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AirtimeReportsCsv = createAsyncThunk(
  "ls/AirtimeReportsCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().AirtimeReports(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AirtimeReportsWithoutDownlinesCsv = createAsyncThunk(
  "ls/AirtimeReportsWithoutDownlinesCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().AirtimeReportsWithoutDownlines(
          payload
        );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const BillsReports = createAsyncThunk(
  "ls/AirtimeReports",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().BillsReports(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const BillsReportsWithoutDownlines = createAsyncThunk(
  "ls/BillsReportsWithoutDownlines",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().BillsReportsWithoutDownlines(
          payload
        );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const BillsReportsCsv = createAsyncThunk(
  "ls/AirtimeReportsCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().BillsReports(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const BillsReportsWithoutDownlinesCsv = createAsyncThunk(
  "ls/BillsReportsWithoutDownlinesCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().BillsReportsWithoutDownlines(
          payload
        );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const DataReports = createAsyncThunk(
  "ls/DataReports",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().DataReports(
        payload
      );
      console.log(response);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const DataReportsWithoutDownlines = createAsyncThunk(
  "ls/DataReportsWithoutDownlines",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().DataReportsWithoutDownlines(
          payload
        );
      console.log(response);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const DataReportsCsv = createAsyncThunk(
  "ls/DataReportsCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().DataReports(
        payload
      );
      console.log(response);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const DataReportsWithoutDownlinesCsv = createAsyncThunk(
  "ls/DataReportsWithoutDownlinesCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().DataReportsWithoutDownlines(
          payload
        );
      console.log(response);
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ReportsSlice = createSlice({
  name: "Reports",
  initialState: "",
  extraReducers: {

    //Eco Bank Starts Here
    [ECOBankTransactions.pending]: (state) => {
       return {
         ...state,
         loading: true,
       };
    },
    [ECOBankTransactions.fulfilled]: (state, action) => {
      return { ...state, ecobank: action.payload, loading: false };
    },
    [ECOBankTransactions.rejected]: (state, action) => {
       return {
         ...state,
         loading: false,
         error: action.payload,
       };
    },

    //Eco Bank Ends Here

    [DataReports.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DataReports.fulfilled]: (state, action) => {
      return {
        ...state,
        DataListReports: action.payload,
        loading: false,
      };
    },
    [DataReports.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [DataReportsWithoutDownlines.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DataReportsWithoutDownlines.fulfilled]: (state, action) => {
      return {
        ...state,
        DataListReportsWithout: action.payload,
        loading: false,
      };
    },
    [DataReportsWithoutDownlines.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [DataReportsCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DataReportsCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        DataListReportsCsv: action.payload,
        loading: false,
      };
    },
    [DataReportsCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [DataReportsWithoutDownlinesCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DataReportsWithoutDownlinesCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        DataListReportsWithoutCsv: action.payload,
        loading: false,
      };
    },
    [DataReportsWithoutDownlinesCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [BillsReports.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [BillsReports.fulfilled]: (state, action) => {
      return {
        ...state,
        BillsListReports: action.payload,
        loading: false,
      };
    },
    [BillsReports.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [BillsReportsWithoutDownlines.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [BillsReportsWithoutDownlines.fulfilled]: (state, action) => {
      return {
        ...state,
        BillsListReportsWithout: action.payload,
        loading: false,
      };
    },
    [BillsReportsWithoutDownlines.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [BillsReportsCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [BillsReportsCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        BillsListReportsCsv: action.payload,
        loading: false,
      };
    },
    [BillsReportsCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [BillsReportsWithoutDownlinesCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [BillsReportsWithoutDownlinesCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        BillsListReportsWithoutCsv: action.payload,
        loading: false,
      };
    },
    [BillsReportsWithoutDownlinesCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AirtimeReports.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AirtimeReports.fulfilled]: (state, action) => {
      return {
        ...state,
        AirtimeReports: action.payload,
        loading: false,
      };
    },
    [AirtimeReports.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AirtimeReportsWithoutDownlines.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AirtimeReportsWithoutDownlines.fulfilled]: (state, action) => {
      return {
        ...state,
        AirtimeReportsWithout: action.payload,
        loading: false,
      };
    },
    [AirtimeReportsWithoutDownlines.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AirtimeReportsCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AirtimeReportsCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        AirtimeReportsCsv: action.payload,
        loading: false,
      };
    },
    [AirtimeReportsCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AirtimeReportsWithoutDownlinesCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AirtimeReportsWithoutDownlinesCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        AirtimeReportsWithoutCsv: action.payload,
        loading: false,
      };
    },
    [AirtimeReportsWithoutDownlinesCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ListTransaction.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ListTransaction.fulfilled]: (state, action) => {
      return {
        ...state,
        TransactionListingReport: action.payload,
        loading: false,
      };
    },
    [ListTransaction.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [MainWalletReport.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [MainWalletReport.fulfilled]: (state, action) => {
      return { ...state, MainWalletReport: action.payload, loading: false };
    },
    [MainWalletReport.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CommissionWalletReport.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CommissionWalletReport.fulfilled]: (state, action) => {
      return { ...state, CommissionReport: action.payload, loading: false };
    },
    [CommissionWalletReport.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [SingleCustomerListing.pending]: (state) => {
      return {
        ...state,
        loadingCustomer: true,
      };
    },
    [SingleCustomerListing.fulfilled]: (state, action) => {
      return {
        ...state,
        SingleCustomer: action.payload,
        loadingCustomer: false,
      };
    },
    [SingleCustomerListing.rejected]: (state, action) => {
      return {
        ...state,
        loadingCustomer: false,
        error: action.payload,
      };
    },

    [CusstomerListing.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CusstomerListing.fulfilled]: (state, action) => {
      return {
        ...state,
        CusstomerListingReport: action.payload,
        loading: false,
      };
    },
    [CusstomerListing.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [TransactionSummary.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TransactionSummary.fulfilled]: (state, action) => {
      return {
        ...state,
        TransactionSummaryReport: action.payload,
        loading: false,
      };
    },
    [TransactionSummary.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default ReportsSlice.reducer;
