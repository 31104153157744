import invoiceApi, { formApiInvoice } from "../invoiceApi";

const InvoiceModule = () => {
  return {
    UploadInvoice: function (data) {
      return formApiInvoice.post("upload-invoice", data);
    },

    SingleInvoice: function (data) {
      return invoiceApi.post("create-single-agent", data);
    },

    deleteErpCode: function (data) {
      return invoiceApi.delete(`delete-single-agent/${data}`);
    },

    // agentsUpload
    AgentsUpload: function (data) {
      return formApiInvoice.post("agents-upload", data);
    },

    GenerateInvoice: function (data) {
      return invoiceApi.post("generate-invoice", data);
    },

    // DebitGraphApi
    DebitGraphApi: function (data) {
      return invoiceApi.get(`monthly-dept-trend?year=${data.year}`);
    },
    DebitGraphApiWeek: function (data) {
      const { month, week } = data;
      return invoiceApi.get(
        `weekly-dept-trend?month=${month}&week=${week || 1}`
      );
    },

    GetInvoicesById: function (data) {
      const { id } = data;
      return invoiceApi.get(`/batch-invoice-entries/${id}`);
    },

    // /batch-invoice-entries/2?page=1&per_page=10&keyword

    GenerateInvoicesBySheetId: function (payload) {
      const { id } = payload;
      return invoiceApi.post(`/generate-invoice-by-sheet-id`, { sheet_id: id });
    },

    GetInvoices: function (data) {
      const {
        page,
        per_page,
        keyword,
        status,
        type,
        end_date,
        start_date,
        sheet_status,
      } = data;
      return invoiceApi.get(
        `invoice-upload-batches?page=${page}&per_page=${per_page}&sheet_status=${
          sheet_status ? sheet_status : ""
        }&type=${type ? type : ""}&keyword=${
          keyword ? keyword : ""
        }&start_date=${start_date ? start_date : ""}&end_date=${
          end_date ? end_date : ""
        }&status=${status ? status : ""}&with_valid_entries=true`
      );
    },

    GetInvoicesFailed: function (data) {
      const {
        page,
        per_page,
        keyword,
        status,
        type,
        end_date,
        start_date,
        sheet_status,
      } = data;
      return invoiceApi.get(
        `invoice-upload-batches?page=${page}&per_page=${per_page}&sheet_status=${
          sheet_status ? sheet_status : ""
        }&type=${type ? type : ""}&keyword=${
          keyword ? keyword : ""
        }&start_date=${start_date ? start_date : ""}&end_date=${
          end_date ? end_date : ""
        }&status=${status ? status : ""}&with_invalid_entries=true`
      );
    },

    GetInvoicesGenerated: function (data) {
      const { page, per_page, keyword, end_date, start_date } = data;
      return invoiceApi.get(
        `issued-invoices?page=${page}&per_page=${per_page}&keyword=${
          keyword ? keyword : ""
        }&start_date=${start_date ? start_date : ""}&end_date=${
          end_date ? end_date : ""
        }`
      );
    },

    // /generated-invoice-stats/{sheet_id}
    GetInvoicesGeneratedDetailsStats: function (data) {
      const { id } = data;
      return invoiceApi.get(`/generated-invoice-stats/${id}`);
    },

    // GetInvoicesGeneratedDetails
    GetInvoicesGeneratedDetails: function (data) {
      const { id } = data;
      return invoiceApi.get(`/invoice/${id}?with_items=true`);
    },

    // GetInvoicesByError
    GetInvoicesByError: function (data) {
      const { page, per_page, keyword, status, type, id } = data;
      return invoiceApi.get(
        `/batch-invoice-entries/${id}?page=${page}&per_page=${per_page}&status=${status}&type=${
          type ? type : ""
        }&keyword=${keyword ? keyword : ""}`
      );
    },

    GetUploadedSapCode: function (data) {
      const { page, per_page, keyword, verified } = data;
      return invoiceApi.get(
        `all-agents?page=${page}&per_page=${per_page}&verified=${verified}&keyword=${
          keyword ? keyword : ""
        }
        `
      );
    },

    GetInvoiceAgeingAnalysis: function (data) {
      const {
        keyword,
        end_date,
        start_date,
        status = "OPEN,IN_PROGRESS",
        type,
      } = data;
      return invoiceApi.get(
        `issued-invoices?keyword=${keyword ? keyword : ""}&start_date=${
          start_date ? start_date : ""
        }&end_date=${end_date ? end_date : ""}&payment_status=${status}&type=${
          type ? type : ""
        }`
      );
    },

    GetInvoiceAgeingAnalysis2: function (data) {
      const { status = "OPEN,IN_PROGRESS", network } = data;
      return invoiceApi.get(
        `issued-invoices-days-range?payment_status=${
          status ? status : ""
        }&days_range=${network ? network : ""}`
      );
    },

    GetClosedInvoice: function (data) {
      const { keyword, status, end_date, start_date, type } = data;
      return invoiceApi.get(
        `issued-invoices?payment_status=${status}&keyword=${
          keyword ? keyword : ""
        }&start_date=${start_date ? start_date : ""}&end_date=${
          end_date ? end_date : ""
        }&type=${type ? type : ""}`
      );
    },

    GetClosedInvoice2: function (data) {
      const { keyword, network } = data;
      return invoiceApi.get(
        `issued-invoices-days-range?payment_status=CLOSED&keyword=${
          keyword ? keyword : ""
        }&days_range=${network ? network : ""}`
      );
    },

    GetIssuedInvoiceStats: function (data) {
      return invoiceApi.get(`issued-invoice-stats`);
    },

    GetInvoicePaymentListing: function (data) {
      const { page, per_page, keyword, status, end_date, start_date } = data;
      return invoiceApi.get(
        `issued-payments?page=${page}&per_page=${per_page}&keyword=${
          keyword ? keyword : ""
        }&start_date=${start_date ? start_date : ""}&end_date=${
          end_date ? end_date : ""
        }&type=${status ? status : ""}`
      );
    },

    StoreLevelDebtApi: function (data) {
      const { page, per_page, keyword, end_date, start_date } = data;
      return invoiceApi.get(
        `store-level-dept?page=${page}&per_page=${per_page}&keyword=${
          keyword ? keyword : ""
        }&start_date=${start_date ? start_date : ""}&end_date=${
          end_date ? end_date : ""
        }`
      );
    },

    StoreLevelAgingAnalysisApi: function (data) {
      const { page, per_page, keyword } = data;
      return invoiceApi.get(
        `agents-dept-report?page=${page}&per_page=${per_page}&keyword=${
          keyword ? keyword : ""
        }`
      );
    },

    DebtPercentageApi: function (data) {
      const { page, per_page, keyword } = data;
      return invoiceApi.get(
        `dept-percentage?page=${page}&per_page=${per_page}&keyword=${
          keyword ? keyword : ""
        }`
      );
    },

    RegionalAgingAnalysisApi: function (data) {
      const { page, per_page, keyword } = data;
      return invoiceApi.get(
        `region-dept-trend?page=${page}&per_page=${per_page}&keyword=${
          keyword ? keyword : ""
        }`
      );
    },

    // /issued-payments-days-range?days_range=30&keyword=&paymen
    GetInvoicePaymentListing2: function (data) {
      const { page, per_page, network } = data;
      return invoiceApi.get(
        `issued-payments-days-range?days_range=${network}&page=${page}&per_page=${per_page}`
      );
    },

    GetInvoiceAgeingAnalysisPaymentHistory: function (data) {
      const { end_date, start_date, id } = data;
      return invoiceApi.get(
        `issued-payments/${id}?start_date=${start_date}&end_date=${end_date}&keyword=`
      );
    },

    // /invoice/1?with_items=true
    GetInvoiceAgeingAnalysisDetails: function (data) {
      const { end_date, start_date, id } = data;
      return invoiceApi.get(
        `invoice/${id}?with_items=true&start_date=${start_date}&end_date=${end_date}&keyword=`
      );
    },
  };
};

export const InvoiceService = {
  InvoiceModule: InvoiceModule,
};
