import api, { formApi } from "../api";

const FundingModule = () => {
  return {

    BulkUploadAgent: function (data) {
      return formApi.post("identity/Agent/uploadagents", data);
    },

    // /Agent/reseller/onboardagent
    FundingCustomers: function (data) {
      return api.post("wallet/Wallet/wallettransfer", data);
    },
  };
};

export const FundingService = {
  FundingModule: FundingModule,
};
