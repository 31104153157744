import api from "../api";

const OtherReportsModule = () => {
  return {
    getWIPAYTransReq: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/transactions?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`
      );
    },

    getWIPAYTransNot: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/notifications?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`
      );
    },

    getWIPAYTransSplit: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/transactionsplits?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`
      );
    },

    getWIPAYAggregatedSplit: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/transactionsplits/aggregate?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`
      );
    },

    CardNetworkPerformance: function (data) {
      const { CardBrand, duration_in_mins } = data;
      return api.get(
        `posnotifications/admin/card-performance?CardBrand=${CardBrand}&duration_in_mins=${duration_in_mins}`,
        data
      );
    },
  };
};

export const OtherReportsService = {
  otherReportsModule: OtherReportsModule,
};
