import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router";

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("user_profile"));

  if (user !== null) {
    return user;
  }
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();

  // const isAuth = accessToken && role === "5";

  return isAuth ? <Outlet /> : <Navigate to={"/"} />;
};

const LoggedInRoute = () => {
  const isAuth = useAuth();
  return !isAuth ? <Outlet /> : <Navigate to={"/dashboard"} />;
};

export { ProtectedRoutes, LoggedInRoute };
