import React, { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Header from "../components/Header";
import Drawer from "../components/Drawer/Drawer";
import SuspenseFallback from "../components/SuspenseFallback";

function Dashboard() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    // <div>
    //   <Drawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
    //   <Header handleDrawerToggle={handleDrawerToggle} />
    //   <main>
    //     <Outlet />
    //   </main>
    // </div>
    // <ThemeProvider theme={theme}>
    <Stack direction="row">
      <Drawer
        style={{ backgroundColor: "red" }}
        isOpen={mobileOpen}
        toggler={handleDrawerToggle}
      />
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          style={{ maxWidth: "98%", margin: "0 auto" }}
          direction="column"
          sx={{ width: "100%" }}
        >
          <Header toggler={handleDrawerToggle} />
          <main id="main-page-content">
            <Suspense fallback={<SuspenseFallback />}>
              <Outlet />
            </Suspense>
          </main>
        </Stack>
      </Stack>
    </Stack>
    // </ThemeProvider>
  );
}

export default Dashboard;
