import React from "react";
import { useDispatch } from "react-redux";
import { IconContext } from "react-icons";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import {
  CgMenuLeftAlt as MenuIcon,
  CgClose as CloseIcon,
} from "react-icons/cg";
import CustomListItem from "../common/CustomListItem";
import Logo from "../../assets/img/logo.svg";
import "../common/CommonStyle/CommonStyle.css";
import { setCurrentDrawerMenu } from "../../redux/actions/AppActions";
import { useNavigate } from "react-router-dom";
// import { Button } from "@mui/material";
import { DrawerURLs } from "./DrawerContext/DrawerURLs";

const DrawerContent = ({ closeHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // //console.log(theme.palette.primary.main);
  const handleListItemClick = (index) => () => {
    dispatch(setCurrentDrawerMenu(index));
  };

  const handleLogout = (event) => {
    localStorage.clear();
    navigate("/");
    // dispatch(logout(navigate));
  };

  const dataSession =
    JSON.parse(localStorage?.getItem("user"))?.pendingVerifications || [];

  const sidebarData = DrawerURLs()?.Urls;

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 280,
        background: "#FFFFFF",
        padding: "10px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={{ padding: "0.75rem 10px", marginBottom: "50px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <img src={Logo} alt="UCARD" id="drawer-logo" />
            <IconContext.Provider
              value={{ size: "28px", color: `#45089A`, padding: "0px" }}
            >
              <IconButton
                className={"show-nav-icon"}
                color="inherit"
                aria-label="open drawer"
                onClick={() => {}}
                edge="start"
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                  },
                }}
                style={{ padding: "0px 0px" }}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                className={"show-nav-icon"}
                color="inherit"
                aria-label="open drawer"
                onClick={closeHandler}
                edge="start"
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                  },
                }}
                style={{ padding: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </IconContext.Provider>
          </Stack>
          {/* <Stack
            alignItems='center'
            justifyContent='center'
            sx={{ padding: "32px 0 40px" }}
          >
            <UserAvatar
              size='78px'
              fontSize='32px'
              firstname={authenticatedUser?.first_name || ""}
              lastname={authenticatedUser?.last_name || ""}
            />
          </Stack> */}
        </ListSubheader>
      }
    >
      <IconContext.Provider value={{ className: "drawer-icons" }}>
        {sidebarData?.splice?.(0, 1)?.map?.((data, index) => (
          <CustomListItem
            key={index * Math.random()}
            icon={data?.icon}
            text={data?.name}
            href={data?.hasChildren === false && data?.href}
            // selected={listSelected === 1}
            onClick={handleListItemClick(index - 1)}
          >
            {data?.hasChildren === true && (
              <List component="div" disablePadding>
                {data?.children?.map((child, index) => (
                  <CustomListItem
                    key={index * Math.random()}
                    text={child?.name}
                    href={child?.href}
                  />
                ))}
              </List>
            )}
          </CustomListItem>
        ))}
        {dataSession?.length === 0 && (
          <>
            {sidebarData
              ?.splice?.(0, sidebarData?.length)
              ?.map?.((data, index) => (
                <CustomListItem
                  key={index}
                  icon={data?.icon}
                  text={data?.name}
                  href={data?.hasChildren === false && data?.href}
                  // selected={listSelected === 1}
                  onClick={handleListItemClick(index - 1)}
                >
                  {data?.hasChildren === true && (
                    <List component="div" disablePadding>
                      {data?.children?.map((child, index) => (
                        <CustomListItem text={child?.name} href={child?.href} />
                      ))}
                    </List>
                  )}
                </CustomListItem>
              ))}
          </>
        )}

        <Box
          sx={{
            padding: "2rem 0px",
            // background: "var(--primary-color)",
          }}
        >
          <button
            className={"custom-logout"}
            style={{
              width: "100%",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "17px",
              color: "#6c757d",
              // display: "flex",
              // flexDirection: "row",
              cursor: "pointer",
              // gap: "15px",
              whiteSpace: "nowrap",
              paddingTop: "1rem",
            }}
            onClick={() => handleLogout()}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                stroke="#25233A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 17L21 12L16 7"
                stroke="#25233A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 12H9"
                stroke="#25233A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span
              style={{
                color: "#25233A",
              }}
            >
              Logout
            </span>
          </button>
        </Box>
      </IconContext.Provider>
    </List>
  );
};

function Drawer({ isOpen, toggler }) {
  const drawerWidth = 280;
  return (
    <Box
      component="nav"
      id="main-drawer"
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      aria-label="Navigation Options"
    >
      <MuiDrawer
        variant="temporary"
        open={isOpen}
        onClose={toggler}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
      <MuiDrawer
        variant="permanent"
        open
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
    </Box>
  );
}

export default Drawer;
