import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { FundingService } from "./FundingService";

export const FundingCustomers = createAsyncThunk(
  "ls/GetSuperdealerResellerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FundingService.FundingModule().FundingCustomers(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const FundingSlice = createSlice({
  name: "Users",
  initialState: "",
  extraReducers: {
    [FundingCustomers.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [FundingCustomers.fulfilled]: (state, action) => {
      return {
        ...state,
        FundingCustomersSuccess: action.payload,
        loading: false,
      };
    },
    [FundingCustomers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default FundingSlice.reducer;
