import { configureStore } from "@reduxjs/toolkit";
import settingReducer from "./Settings/SettingsSlice";
import authReducer from "./Auth/AuthSlice";
import reportsReducer from "./Reports/ReportsSlice";
import usersReducer from "./Users/UsersSlice";
import productsReducer from "./Products/ProductsSlice";
import fundingReducer from "./Funding/FundingSlice";
import debitReducer from "./Debit/DebitSlice";
import performanceReducer from "./Performance/PerformanceSlice";
import otherReportsReducer from  "./OtherReports/OtherReportsSlice"
import invoiceReducer from "./Invoice/InvoiceSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingReducer,
    reports: reportsReducer,
    users: usersReducer,
    products: productsReducer,
    funding: fundingReducer,
    debits: debitReducer,
    performance: performanceReducer,
    otherReports: otherReportsReducer,
    invoices: invoiceReducer,
  },
});

export default store;
