import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import { InvoiceService } from "./InvoiceService";
import { InvoiceService } from "./InvoiceService";

export const UploadInvoice = createAsyncThunk(
  "ls/GetSuperdealerResellerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().UploadInvoice(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SingleInvoice = createAsyncThunk(
  "ls/SingleInvoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().SingleInvoice(
        payload
      );
      toast.success("Invoice Created Successfully");
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// AgentsUpload
export const AgentsUpload = createAsyncThunk(
  "ls/GetSuperdealerResellerProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().AgentsUpload(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// deleteErpCode
export const deleteErpCode = createAsyncThunk(
  "ls/deleteErpCode",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().deleteErpCode(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoices = createAsyncThunk(
  "ls/GetInvoices",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().GetInvoices(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoicesFailed = createAsyncThunk(
  "ls/GetInvoicesFailed",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().GetInvoicesFailed(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoicesGeneratedDetailsStats = createAsyncThunk(
  "ls/GetInvoicesGeneratedDetailsStats",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoicesGeneratedDetailsStats(
          payload
        );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoicesGenerated = createAsyncThunk(
  "ls/GetInvoicesGenerated",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoicesGenerated(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoicesGeneratedDetails = createAsyncThunk(
  "ls/GetInvoicesGeneratedDetails",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoicesGeneratedDetails(
          payload
        );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoicesByError = createAsyncThunk(
  "ls/GetInvoicesByError",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().GetInvoicesByError(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoicesById = createAsyncThunk(
  "ls/GetInvoicesById",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().GetInvoicesById(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// GenerateInvoicesBySheetId
export const GenerateInvoicesBySheetId = createAsyncThunk(
  "ls/GenerateInvoicesBySheetId",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GenerateInvoicesBySheetId(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// generateInvoice
export const GenerateInvoice = createAsyncThunk(
  "ls/GenerateInvoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().GenerateInvoice(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// GetUploadedSapCode
export const GetUploadedSapCode = createAsyncThunk(
  "ls/GetUploadedSapCode",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().GetUploadedSapCode(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// GetInvoiceAgeingAnalysis
export const GetInvoiceAgeingAnalysis = createAsyncThunk(
  "ls/GetInvoiceAgeingAnalysis",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoiceAgeingAnalysis(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// GetClosedInvoice
export const GetClosedInvoice = createAsyncThunk(
  "ls/GetInvoiceAgeingAnalysis",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().GetClosedInvoice(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetClosedInvoice2 = createAsyncThunk(
  "ls/GetInvoiceAgeingAnalysis",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.InvoiceModule().GetClosedInvoice2(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// GetInvoiceAgeingAnalysis
export const GetInvoiceAgeingAnalysis2 = createAsyncThunk(
  "ls/GetInvoiceAgeingAnalysis",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoiceAgeingAnalysis2(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// GetInvoiceAgeingAnalysis
export const GetInvoicePaymentListing = createAsyncThunk(
  "ls/GetInvoicePaymentListing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoicePaymentListing(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// StoreLevelAgingAnalysisApi
export const StoreLevelAgingAnalysisApi = createAsyncThunk(
  "ls/StoreLevelAgingAnalysisApi",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().StoreLevelAgingAnalysisApi(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// StoreLevelDebtApi
export const StoreLevelDebtApi = createAsyncThunk(
  "ls/StoreLevelDebtApi",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().StoreLevelDebtApi(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);


// DebtPercentageApi
export const DebtPercentageApi = createAsyncThunk(
  "ls/DebtPercentageApi",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().DebtPercentageApi(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// RegionalAgingAnalysisApi
export const RegionalAgingAnalysisApi = createAsyncThunk(
  "ls/RegionalAgingAnalysisApi",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().RegionalAgingAnalysisApi(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// debit graph
export const DebitGraphApi = createAsyncThunk(
  "ls/DebitGraphApi",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().DebitGraphApi(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const DebitGraphApiWeek = createAsyncThunk(
  "ls/DebitGraphApiWeek",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().DebitGraphApiWeek(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoicePaymentListing2 = createAsyncThunk(
  "ls/GetInvoicePaymentListing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoicePaymentListing2(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// /issued-invoice-stats
export const GetIssuedInvoiceStats = createAsyncThunk(
  "ls/GetIssuedInvoiceStats",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetIssuedInvoiceStats(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetInvoiceAgeingAnalysisPaymentHistory = createAsyncThunk(
  "ls/GetInvoiceAgeingAnalysisPaymentHistory",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoiceAgeingAnalysisPaymentHistory(
          payload
        );
      return response?.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

// GetInvoiceAgeingAnalysisDetails
export const GetInvoiceAgeingAnalysisDetails = createAsyncThunk(
  "ls/GetInvoiceAgeingAnalysisDetails",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.InvoiceModule().GetInvoiceAgeingAnalysisDetails(
          payload
        );
      return response?.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const InvoiceSlice = createSlice({
  name: "Users",
  initialState: "",
  extraReducers: {
    [GetInvoices.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoices.fulfilled]: (state, action) => {
      return {
        ...state,
        ViewInvoicesSuccess: action.payload,
        loading: false,
      };
    },
    [GetInvoices.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [SingleInvoice.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [SingleInvoice.fulfilled]: (state, action) => {
      return {
        ...state,
        ViewInvoicesSuccess: action.payload,
        loading: false,
      };
    },
    [SingleInvoice.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetInvoicesFailed.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoicesFailed.fulfilled]: (state, action) => {
      return {
        ...state,
        ViewInvoicesSuccess: action.payload,
        loading: false,
      };
    },
    [GetInvoicesFailed.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetInvoicesGenerated.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoicesGenerated.fulfilled]: (state, action) => {
      return {
        ...state,
        ViewInvoicesSuccess: action.payload,
        loading: false,
      };
    },
    [GetInvoicesGenerated.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetInvoicesGeneratedDetails.fulfilled]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoicesGeneratedDetails.fulfilled]: (state, action) => {
      return {
        ...state,
        invoicesGeneratedDetails: action.payload,
        loading: false,
      };
    },
    [GetInvoicesGeneratedDetails.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // GetInvoicesGeneratedDetailsStats

    [GetInvoicesGeneratedDetailsStats.fulfilled]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoicesGeneratedDetailsStats.fulfilled]: (state, action) => {
      return {
        ...state,
        invoicesGeneratedDetailsStats: action.payload,
        loading: false,
      };
    },
    [GetInvoicesGeneratedDetailsStats.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetInvoicesByError.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoicesByError.fulfilled]: (state, action) => {
      return {
        ...state,
        ViewInvoicesSuccess: action.payload,
        loading: false,
      };
    },
    [GetInvoicesByError.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetUploadedSapCode.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetUploadedSapCode.fulfilled]: (state, action) => {
      return {
        ...state,
        ViewUploadedSapCode: action.payload,
        loading: false,
      };
    },
    [GetUploadedSapCode.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetInvoiceAgeingAnalysis.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoiceAgeingAnalysis.fulfilled]: (state, action) => {
      return {
        ...state,
        invoiceAgeingAnalysisData: action.payload,
        loading: false,
      };
    },
    [GetInvoiceAgeingAnalysis.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetInvoiceAgeingAnalysisPaymentHistory.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoiceAgeingAnalysisPaymentHistory.fulfilled]: (state, action) => {
      return {
        ...state,
        invoiceAgeingAnalysisPaymentHistory: action.payload,
        loading: false,
      };
    },
    [GetInvoiceAgeingAnalysisPaymentHistory.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetInvoiceAgeingAnalysisDetails.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoiceAgeingAnalysisDetails.fulfilled]: (state, action) => {
      return {
        ...state,
        invoiceAgeingAnalysisDetails: action.payload,
        loading: false,
      };
    },
    [GetInvoiceAgeingAnalysisDetails.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetIssuedInvoiceStats.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetIssuedInvoiceStats.fulfilled]: (state, action) => {
      return {
        ...state,
        invoiceStats: action.payload,
        loading: false,
      };
    },
    [GetIssuedInvoiceStats.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // GetInvoicePaymentListing
    [GetInvoicePaymentListing.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoicePaymentListing.fulfilled]: (state, action) => {
      return {
        ...state,
        PaymentListing: action.payload,
        loading: false,
      };
    },
    [GetInvoicePaymentListing.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // StoreLevelAgingAnalysis
    [StoreLevelAgingAnalysisApi.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [StoreLevelAgingAnalysisApi.fulfilled]: (state, action) => {
      return {
        ...state,
        storeLevelAgingAnalysis: action.payload,
        loading: false,
      };
    },
    [StoreLevelAgingAnalysisApi.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // StoreLevelDebtApi
    [StoreLevelDebtApi.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [StoreLevelDebtApi.fulfilled]: (state, action) => {
      return {
        ...state,
        storeLevelDebt: action.payload,
        loading: false,
      };
    },
    [StoreLevelDebtApi.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // DebtPercentageApi
    [DebtPercentageApi.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DebtPercentageApi.fulfilled]: (state, action) => {
      return {
        ...state,
        debtPercentage: action.payload,
        loading: false,
      };
    },
    [DebtPercentageApi.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // RegionalAgingAnalysis
    [RegionalAgingAnalysisApi.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [RegionalAgingAnalysisApi.fulfilled]: (state, action) => {
      return {
        ...state,
        regionalAgingAnalysis: action.payload,
        loading: false,
      };
    },
    [RegionalAgingAnalysisApi.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // DebitGraphApi
    [DebitGraphApi.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DebitGraphApi.fulfilled]: (state, action) => {
      return {
        ...state,
        debitGraphData: action?.payload,
        loading: false,
      };
    },
    [DebitGraphApi.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },


    [DebitGraphApiWeek.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DebitGraphApiWeek.fulfilled]: (state, action) => {
      return {
        ...state,
        debitGraphDataWeek: action?.payload,
        loading: false,
      };
    },
    [DebitGraphApiWeek.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    //
    [GetInvoicesById.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoicesById.fulfilled]: (state, action) => {
      return {
        ...state,
        invoicesById: action.payload,
        loading: false,
      };
    },
    [GetInvoicesById.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default InvoiceSlice.reducer;
