import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PerformanceService } from "./PerformanceService";

export const GetPerformance = createAsyncThunk(
  "ls/GetPerformance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await PerformanceService.performanceModule().GetCoreReports(payload);

      const res = JSON.parse(response?.data);

      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const ListTransaction = createAsyncThunk(
  "ls/ListTransaction",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log({ payload });
      const response =
        await PerformanceService.performanceModule().GetCoreReports(payload);
      const res = JSON.parse(response?.data);
      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const ListTransactionCsv = createAsyncThunk(
  "ls/ListTransactionCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log({ payload });
      const response =
        await PerformanceService.performanceModule().GetCoreReports(payload);
      const res = JSON.parse(response?.data);
      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const CusstomerListing = createAsyncThunk(
  "ls/CusstomerListing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log({ payload });
      const response =
        await PerformanceService.performanceModule().GetCoreReports(payload);
      const res = JSON.parse(response?.data);
      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const AgentPerformanceList = createAsyncThunk(
  "ls/AgentPerformanceList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log({ payload });
      const response =
        await PerformanceService.performanceModule().GetCoreReports(payload);
      const res = JSON.parse(response?.data);
      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const AgentProductPerformanceList = createAsyncThunk(
  "ls/AgentProductPerformanceList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log({ payload });
      const response =
        await PerformanceService.performanceModule().GetCoreReports(payload);
      const res = JSON.parse(response?.data);
      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const AgentPerformanceListFromReseller = createAsyncThunk(
  "ls/AgentPerformanceListFromReseller",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log({ payload });
      const response =
        await PerformanceService.performanceModule().GetCoreReports(payload);
      const res = JSON.parse(response?.data);
      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const ResellerPerformanceList = createAsyncThunk(
  "ls/ResellerPerformanceList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log({ payload });
      const response =
        await PerformanceService.performanceModule().GetCoreReports(payload);
      const res = JSON.parse(response?.data);
      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const PerformanceSlice = createSlice({
  name: "Performance",
  initialState: "",
  extraReducers: {
    [AgentProductPerformanceList.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentProductPerformanceList.fulfilled]: (state, action) => {
      return {
        ...state,
        agentProductPerformance: action.payload,
        loading: false,
      };
    },
    [AgentProductPerformanceList.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentPerformanceList.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentPerformanceList.fulfilled]: (state, action) => {
      return {
        ...state,
        agentPerformance: action.payload,
        loading: false,
      };
    },
    [AgentPerformanceList.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CusstomerListing.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CusstomerListing.fulfilled]: (state, action) => {
      return {
        ...state,
        CusstomerListing: action.payload,
        loading: false,
      };
    },
    [CusstomerListing.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ListTransaction.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ListTransaction.fulfilled]: (state, action) => {
      return {
        ...state,
        ListTransaction: action.payload,
        loading: false,
      };
    },
    [ListTransaction.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ListTransactionCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ListTransactionCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        ListTransactionCsv: action.payload,
        loading: false,
      };
    },
    [ListTransactionCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentPerformanceListFromReseller.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentPerformanceListFromReseller.fulfilled]: (state, action) => {
      return {
        ...state,
        agentPerformanceListFromReseller: action.payload,
        loading: false,
      };
    },
    [AgentPerformanceListFromReseller.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ResellerPerformanceList.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ResellerPerformanceList.fulfilled]: (state, action) => {
      return {
        ...state,
        resellerPerformance: action.payload,
        loading: false,
      };
    },
    [ResellerPerformanceList.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetPerformance.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetPerformance.fulfilled]: (state, action) => {
      return {
        ...state,
        performance: action.payload,
        loading: false,
      };
    },
    [GetPerformance.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default PerformanceSlice.reducer;
