import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Typography from "@mui/material/Typography";

import BreadCrumb from "../../components/common/CustomBreadCrumbs";

import "./styles.scss";
import {
  GetBanks,
  SendOTP,
  ValidateOTP,
  VerifyBankAccount,
  VerifyPassword,
  ResendOTP,
  AddUserBankAccount,
} from "../../utils/redux/Settings/SettingsSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SuccessDisplay from "./Success/SuccessDisplay";
import { CurrentUser } from "../../utils/redux/Auth/AuthSlice";

const ChangeBankAccount = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({});

  const dispatch = useDispatch();
  const data = useSelector((state) => state);
  const location = useLocation();


  const [l, setl] = useState(location.state);



  const accountData = data?.settings?.verifiedBank?.responseModel;

  const user = data?.auth?.user?.responseModel;

  const BanksInNigeria = data?.settings?.banks?.responseModel;

  // const val = BanksInNigeria?.map((x) => x?.bankCode)?.filter(
  //   (x) => x === accountData?.bankCode
  // ).map(x => x);
  const navigate = useNavigate();

  const val = BanksInNigeria?.find(
    (x) => x?.bankCode === accountData?.bankCode
  );

  // console.log({ val });

  useEffect(() => {
    dispatch(GetBanks());
    dispatch(CurrentUser());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    // //console.log("clicked")
    dispatch(
      AddUserBankAccount({
        bankCode: accountData?.bankCode,
        bankName: val?.bankName,
        accountNumber: accountData?.accountNumber,
        requestHash:
          data?.settings?.verifiedPassword?.responseModel?.requestHash,
      })
    ).then((res) => {
      if (res?.payload?.isSuccessful === true) {
        setSuccessView(true);
        setSearchParams({});
        // if (l.pushTo === "transfer") {
        //   navigate("/dashboard?pushTo=transfer");
        // }
      }
    });
  };

  return (
    <React.Fragment>
      <section>
        <nav id="quick-nav">
          <BreadCrumb
            breadcrumbs={[
              <Typography key="1" color="inherit">
                Settings / Add Bank Account
              </Typography>,
            ]}
          />
        </nav>
        {successView && (
          <SuccessDisplay
            action={() =>
              l.pushTo === "transfer" && navigate("/dashboard?pushTo=transfer")
            }
            buttonName={"Continue Transfer"}
            message={"Bank Account Added Successfully"}
          />
        )}

        {step1 && (
          <div className="change-pin-container">
            <div className="stepper-header">
              <p></p>
              <p className="steps">Step 1/4</p>
            </div>
            <Formik
              initialValues={{
                password: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.password) {
                  errors.password = "Password is required";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                // setStep1(false);
                // setStep2(true);
                dispatch(
                  VerifyPassword({
                    password: values.password,
                    verificationType: 0,
                  })
                )?.then((res) => {
                  //console.log(res);
                  if (res?.payload?.isSuccessful === true) {
                    setStep1(false);
                    setStep2(true);
                    setSearchParams({
                      ...searchParams,
                      // rkh: res?.payload?.responseModel?.requestHash,
                    });
                  }
                });
                // setIsLoading(true);
                // const body = {
                //   password: values.password,
                //     password: values.password,
                // };
                // try {
                //   const res = await axiosServices.post(`/user/login`, body);
                //   dispatch({
                //     type: LOGIN_SUCCESS,
                //     payload: res.data,
                //   });
                //   // dispatch(getUserDetail());
                //   setIsLoading(false);
                //   toast.success("Login Successful");
                // } catch (error) {
                //   dispatch({
                //     type: LOGIN_FAIL,
                //   });
                //   // const errors = {};
                //   // for (const key in error.response.data.errors) {
                //   //   errors[key] = error.response.data.errors[key][0];
                //   // }
                //   // setSubmitting(false);
                //   // //console.log("error", error.response.data.errors);
                //   // for (const key in errors) {
                //   //   toast.error(errors[key]);
                //   // }
                //   toast.error("Login Failed");
                //   setIsLoading(false);
                //   setSubmitting(false);
                // }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form_input_wrapper password-input">
                    <label>
                      Input password <span>*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.password}
                      placeholder="**********"
                      className={
                        errors.password && touched.password
                          ? "input-error"
                          : null
                      }
                    />
                    {errors.password && touched.password && (
                      <span className="error">{errors.password}</span>
                    )}
                    <div className="notice">
                      <p>We’ll confirm if your password is valid.</p>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="login-btn"
                      // onClick={() => {
                      //   setStep1(false);
                      //   setStep2(true);
                      // }}
                    >
                      {data?.settings?.loading ? "Please wait..." : "Continue"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}

        {step2 && (
          <div className="change-pin-container">
            <div className="stepper-header">
              <p
                className="go_back"
                onClick={() => {
                  setStep1(true);
                  setStep2(false);
                }}
              >
                <span>
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 1L1 7L7 13"
                      stroke="#25233A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>Back</span>
              </p>
              <p className="steps">Step 2/4</p>
            </div>
            <Formik
              initialValues={{
                bank: user?.bankCode,
                account_number: user?.accountNumber,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.bank) {
                  errors.bank = "bank is required";
                }
                if (!values.account_number) {
                  errors.account_number = "account number is required";
                  // }

                  return errors;
                }
              }}
              onSubmit={async (values, { setSubmitting }) => {
                dispatch(
                  VerifyBankAccount({
                    bankCode: values.bank,
                    accountNumber: values?.account_number.toString(),
                  })
                )?.then((res) => {
                  if (res?.payload?.isSuccessful === true) {
                    dispatch(
                      SendOTP({
                        mobileOtp: true,
                        emailOtp: true,
                        otpVerificationType: 0,
                      })
                    ).then((res) => {
                      if (res?.payload?.isSuccessful === true) {
                        setSearchParams({
                          ...searchParams,
                          bank: values.bank,
                          account_number: values.account_number,
                        });
                        setStep2(false);
                        setStep3(true);
                      }
                    });
                  }
                });

                // ?.then((res) => { //console.log(res)}));

                // setStep2(false);
                // setStep3(true);

                // setIsLoading(true);
                // const body = {
                //   email: values.email,
                //     password: values.password,
                // };
                // try {
                //   const res = await axiosServices.post(`/user/login`, body);
                //   dispatch({
                //     type: LOGIN_SUCCESS,
                //     payload: res.data,
                //   });
                //   // dispatch(getUserDetail());
                //   setIsLoading(false);
                //   toast.success("Login Successful");
                // } catch (error) {
                //   dispatch({
                //     type: LOGIN_FAIL,
                //   });
                //   // const errors = {};
                //   // for (const key in error.response.data.errors) {
                //   //   errors[key] = error.response.data.errors[key][0];
                //   // }
                //   // setSubmitting(false);
                //   // //console.log("error", error.response.data.errors);
                //   // for (const key in errors) {
                //   //   toast.error(errors[key]);
                //   // }
                //   toast.error("Login Failed");
                //   setIsLoading(false);
                //   setSubmitting(false);
                // }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form_input_wrapper">
                    <label>
                      Select bank <span>*</span>
                    </label>
                    <select
                      name="bank"
                      onChange={handleChange}
                      value={values.bank}
                      className={
                        errors.bank && touched.bank ? "input-error" : null
                      }
                    >
                      <>
                        <option value=""></option>
                        {BanksInNigeria?.map((item, index) => (
                          <option key={index} value={item.bankCode}>
                            {item.bankName}
                          </option>
                        ))}
                      </>
                      {/* <option value="">Select bank</option>
                      <option value="1">Bank 1</option>
                      <option value="2">Bank 2</option>
                      <option value="3">Bank 3</option> */}
                    </select>
                    {errors.bank && touched.bank && (
                      <span className="error">{errors.bank}</span>
                    )}
                  </div>

                  <div className="form_input_wrapper">
                    <label>
                      Input account number <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="account_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.account_number}
                      className={
                        errors.account_number && touched.account_number
                          ? "input-error"
                          : null
                      }
                    />
                    {errors.account_number && touched.account_number && (
                      <span className="error">{errors.account_number}</span>
                    )}
                    <div className="notice">
                      <p>We’ll send OTP to this account phone number.</p>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="login-btn"
                      // onClick={() => navigate("/reset-password-success")}
                    >
                      {data?.settings?.loading ? "Sending OTP..." : "Continue"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}

        {step3 && (
          <div className="change-pin-container">
            <div className="stepper-header">
              <p
                className="go_back"
                onClick={() => {
                  setStep2(true);
                  setStep3(false);
                }}
              >
                <span>
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 1L1 7L7 13"
                      stroke="#25233A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>Bank</span>
              </p>
              <p className="steps">Step 3/4</p>
            </div>
            <Formik
              initialValues={{
                otp: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.otp) {
                  errors.otp = "OTP is required";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                dispatch(
                  ValidateOTP({
                    otp: values.otp?.toString(),
                    requestHash:
                      data?.settings?.sendOTP?.responseModel?.requestHash,
                    optType: "",
                  })
                )?.then((res) => {
                  console.log(res);
                  if (res?.payload?.isSuccessful === true) {
                    setStep3(false);
                    setStep4(true);
                  }
                });

                // setIsLoading(true);
                // const body = {
                //   email: values.email,
                //     password: values.password,
                // };
                // try {
                //   const res = await axiosServices.post(`/user/login`, body);
                //   dispatch({
                //     type: LOGIN_SUCCESS,
                //     payload: res.data,
                //   });
                //   // dispatch(getUserDetail());
                //   setIsLoading(false);
                //   toast.success("Login Successful");
                // } catch (error) {
                //   dispatch({
                //     type: LOGIN_FAIL,
                //   });
                //   // const errors = {};
                //   // for (const key in error.response.data.errors) {
                //   //   errors[key] = error.response.data.errors[key][0];
                //   // }
                //   // setSubmitting(false);
                //   // //console.log("error", error.response.data.errors);
                //   // for (const key in errors) {
                //   //   toast.error(errors[key]);
                //   // }
                //   toast.error("Login Failed");
                //   setIsLoading(false);
                //   setSubmitting(false);
                // }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h2>
                    A confirmation code was sent to{" "}
                    {user?.emailAddress?.slice(0, 4)}****
                    {user?.emailAddress?.slice(
                      user?.emailAddress?.length - 9,
                      user?.emailAddress?.length
                    )}
                  </h2>
                  <div className="form_input_wrapper password-input">
                    <label>
                      Input OTP <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="otp"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.otp}
                      placeholder=""
                      className={
                        errors.otp && touched.otp ? "input-error" : null
                      }
                    />
                    {/* <span className='otp-toogle-icon'>{ToggleIcon}</span> */}
                    {errors.otp && touched.otp && (
                      <span className="error">{errors.otp}</span>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="login-btn"
                      // onClick={() => navigate("/reset-password-success")}
                    >
                      {isLoading ? "Confirm OTP..." : "Confirm"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "12px",
                fontWeight: "600",
              }}
            >
              Don’t get OTP?{" "}
              <span
                onClick={() =>
                  dispatch(
                    ResendOTP({
                      mobileOtp: true,
                      emailOtp: true,
                      requestHash:
                        data?.settings?.sendOTP?.responseModel?.requestHash,
                    })
                  )
                }
                style={{ color: "var(--primary-color)", cursor: "pointer" }}
              >
                Resend
              </span>{" "}
            </p>
          </div>
        )}

        {step4 && (
          <div className="change-pin-container">
            <div className="stepper-header">
              <p
                className="go_back"
                onClick={() => {
                  setStep3(true);
                  setStep4(false);
                }}
              >
                <span>
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 1L1 7L7 13"
                      stroke="#25233A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>Bank</span>
              </p>
              <p className="steps">Step 4/4</p>
            </div>
            <div>
              <h2>Account Detail Summary</h2>
              <div style={{ marginBottom: "30px" }}>
                <p>Bank Name: {val?.bankName}</p>
                <p>Account Number: {accountData?.accountNumber}</p>
                <p>Account Name: {accountData?.accountName}</p>
              </div>

              <div>
                <button
                  className="login-btn"
                  type="submit"
                  onClick={handleChange}
                >
                  {/* // onClick={() => navigate("/reset-password-success")} */}
                  {data?.settings?.loading
                    ? "Creating Bank Account..."
                    : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default ChangeBankAccount;
