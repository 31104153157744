import api, { formApi } from "../api";

const DebitModule = () => {
  return {

    BulkUploadAgent: function (data) {
      return formApi.post("identity/Agent/uploadagents", data);
    },

    DebitCustomers: function (data) {
      return api.post("wallet/Wallet/superdealer/debit", data);
    },

    ViewDebitedCustomers: function () {
      return api.get("wallet/Wallet/superdealer/debitorders");
    },
  };
};

export const DebitService = {
  DebitModule: DebitModule,
};
